@import '../../stylesheets/main.scss';
.statement-table td, th{
    padding: .75rem;
}

.statement-table td:first-child, .statement-table th:first-child {
    cursor: pointer;
    width: 75%;
}

.table-data-collapse .fa-eye, .table-data-collapse .fa-file-pdf-o{
    font-size: 18px;
    cursor: pointer;
}

.statement-table tbody.collapse.in {
    display: table-row-group;
  }
  .statement-table thead th {
    vertical-align: bottom;
    font-size: 20px;
    color: #58595B;
}
.statement-table tbody tr {
    border: 1px solid #333;
}

.table-data-collapse{
    background: #EDEDED !important;
}
.table-data-collapse td{
    font-size: 0.9em
}
.statement-table .hide{
    display: none;
}
.statement-table .show{
    display: table-row;
}

.select-year{
    font-size: 20px;
    font-weight: 500;
    color: #7b7a79;
    background: #ffffff;
    padding: 10px 14px;
    margin-bottom: 0;
    margin-left: 5px;
    border: 1px solid #bfbfbf;
}

.search-input-box{
    width: 372px;
    margin-left: 25px;
    font-style: italic;
    font-size: 17px;
    font-weight: 500;
    color: #7b7a79;
    background: #ffffff;
    padding: 10px 14px;
    margin-bottom: 0;
    border: 1px solid #bfbfbf;
}
.search-input-box:focus{
    outline: #bfbfbf auto 1px;
}
.statement-search{
    position: relative;
}
.search-font-awesome{
    position: absolute;
    right: 15px;
    top: 11px;
    font-size: 22px !important;
    color:#616161;
    cursor: pointer;
}
#myTable th:first-child, #myTable td:first-child {
    min-width: 250px;
    /* width: 250px; */
}
.transition {
    transition: all .3s linear;
}
.show-more-btn{
    display: none
}

.statement-parent-ele, .monthly-statement{
    width:100%;
    display:contents
}


@media(min-width: 1400px){
    .table-data-collapse .fa-eye, .table-data-collapse .fa-file-pdf-o{
        font-size: 22px;
        cursor: pointer;
    }
    .select-year{
        font-size: 20px;
        font-weight: 500;
        color: #7b7a79;
        background: #ffffff;
        padding: 14px 16px;
        margin-bottom: 15px;
        border: 1px solid #bfbfbf;
    }

    .search-input-box{
        width: 372px;
        margin-left: 25px;
        font-style: italic;
        font-size: 17px;
        font-weight: 500;
        color: #7b7a79;
        background: #ffffff;
        padding: 15px 16px;
        margin-bottom: 15px;
        border: 1px solid #bfbfbf;
    }
    .search-input-box:focus{
        outline: #bfbfbf auto 1px;
    }
    .statement-search{
        position: relative;
    }
    .search-font-awesome{
        position: absolute;
        right: 15px;
        top: 17px;
        font-size: 22px !important;
        color:#616161
    }
}
@media(max-width: 768px){
    .statement-table thead th {
        font-size: 14px;
    }
}
@media(max-width: 640px){
    .statement-table td:first-child, .statement-table th:first-child {
        cursor: pointer;
        width:100%;
    }
    .statement-table td:last-child {
        border-right: none !important;
    }
    .statement-table td {
        color: #58595B;
        padding: 4px !important;
    }
    .table-accordion{
        border:none !important
    }
    .statement-table .show {
        display: grid !important;
    }
    .statement-table .hide, .statement-table .tbl-hide{
        display: none !important;
    }
    .payment-history-table{
        border: 1px solid #d2d0d0;

    }
    .statement-table .tbl-show{
        display: block;
    }
    .statement-table .show-more-btn{
        display: inline-block;
        padding: 0;
        min-width: 100px;
        height: auto;
        cursor: pointer;
    }
    .statement-search {
        position: relative;
        width: 100%
    }
    .search-input-box {
        width: 100%;
        margin-left: 0;
        font-style: italic;
        font-size: 15px;
        font-weight: 500;
        color: #7b7a79;
        background: #ffffff;
        padding: 10px 14px;
        margin-bottom: 15px;
        border: 1px solid #bfbfbf;
    }
    .search-font-awesome {
        position: absolute;
        right: 10px;
        top: 14px;
        font-size: 16px !important;
        color: #616161;
        cursor: pointer;
    }
    .select-year {
        font-size: 15px;
        font-weight: 500;
        color: #7b7a79;
        background: #ffffff;
        padding: 10px 10px;
        margin-bottom: 15px;
        border: 1px solid #bfbfbf;
    }
    .payment-history-year{
        display: flex;
        font-size: 17px;
        padding: 7px 11px;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        border-bottom: 1px solid #eee;
    }
    .responsiveTable tbody tr.table-data-collapse {
        border:1px solid #dedede !important;
    }
}

