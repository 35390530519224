@import '../../stylesheets/main.scss';

.pass-container {
  text-align: left;
}

.password-required-tick::before {
  background-image: url(../../assets/images/svgs/tick.svg);
  height: 13px;
  width: 13px;
  content: '';
  display: block;
  position: absolute;
  background-size: 13px 13px;
  background-repeat: no-repeat;
  margin-top: 4px;
  left: 0;
}

.password-required::before {
  background-image: url(../../assets/images/svgs/ex.svg);
  height: 10px;
  width: 10px;
  content: '';
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  left: 0px;
  top: 6px;
}

@media screen and (min-width: 1400px) {
  .password-required-tick::before {
    background-image: url(../../assets/images/svgs/tick.png);
    height: 20.44px;
    width: 20.44px;
    content: '';
    display: block;
    position: absolute;
    background-size: 19.44px 19.44px;
    background-repeat: no-repeat;
    top: -3px;
    left: 0;
  }

  .password-required::before {
    height: 17.44px;
    width: 17.44px;
    content: '';
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    left: 0px;
    top: 8px;
  }
}