@import '../../stylesheets/main.scss';

.new-customer{
  &:hover {
    text-decoration: none;
    color: $lighter-black;
  }
}

/*==media query max-width:768==*/
@media (max-width:768px){
  .forgot-pass{
    font-size: 14px;
  }
}
@media screen and (min-width: 1400px) {
  .new-customer{
    font-size: 14px;
  }
}
