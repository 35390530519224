@import '../../stylesheets/main.scss';

// .my-coverage-block:last-child{
//     margin-right: 0
// }
.my-coverage-block{
    width: 415px;
    align-items: center;
    min-height: 210px;
    border: 3px solid #EFEFEF;
    padding: 40px 25px;
    margin-right: 26px;
    margin-top: 2em;
    position: relative;
}
/* .my-coverage-block:hover, .my-coverage-block.active{
    cursor: pointer;
    border: 5px solid #F63200;
} */
.product-type-text{
    font-size: 28px
}
.coverage-details, .provider-name{
    float: left;
    width: 100%;
}
.coverage-details li:first-child{
    list-style-type: none;
    margin-left: 0;
}
.coverage-details li{
    list-style: disc;
    float: left;
    padding: 1px 1px;
    margin-left: 28px;
}


@media(max-width:1366px) and (min-width:1024px){
    .my-coverage-block {
        width: 340px;
        min-height: 145px;
        padding: 25px;
        border: 3px solid #EFEFEF;
        margin-right: 20px;
        margin-top: 1em;
        position: relative;
    }
    .product-type-text{
        font-size: 28px
    }
    .coverage-details li:first-child::before{
        list-style-type: none;
        margin-left: 0;
        content: "";
    }
    .coverage-details li::before {
        list-style: none;
        float: left;
        padding: 1px 1px;
        content: "/";
    }
    .coverage-details li{
        list-style: none;
        float: left;
        padding: 1px 1px;
        margin-left: 0px;
    }
}
@media(max-width:1023px) and (min-width:768px){
    .my-coverage-block {
        width: 330px;
        min-height: 145px;
        padding: 20px;
        margin-right: 12px;
        margin-top: 1em;
        border: 3px solid #EFEFEF;
        position: relative;
    }
    .product-type-text{
        font-size: 25px
    }
    .coverage-details li:first-child::before{
        list-style-type: none;
        margin-left: 0;
        content: "";
    }
    .coverage-details li::before {
        list-style: none;
        float: left;
        padding: 1px 1px;
        content: "/";
    }
    .coverage-details li{
        list-style: none;
        float: left;
        padding: 1px 1px;
        margin-left: 0px;
    }
}
@media(max-width: 767px){
    .my-coverage-block {
        min-width: 382;
        min-height: 145px;
        padding: 20px;
        border: 3px solid #EFEFEF;
        margin-right: 0;
        margin-top: 1em;
        position: relative;
    }
    .product-type-text{
        font-size: 25px;
    }
}
@media(max-width:640px){
    .coverage-details li:first-child::before{
        list-style-type: none;
        margin-left: 0;
        content: "";
    }
    .coverage-details li::before {
        list-style: none;
        float: left;
        padding: 1px 1px;
        content: "/";
    }
    .coverage-details li{
        list-style: none;
        float: left;
        padding: 1px 1px;
        margin-left: 0px;
    }
    .my-coverage-block {
        width: 346px;
        min-height: 145px;
        padding: 15px;
        border: 3px solid #EFEFEF;
        margin-right: 0;
        margin-top: 1em;
        position: relative;
    }
}