/* Jeep wrangler */
@import '../../stylesheets/main.scss';

.account-num {
  font-size: 22px;
}
.account-sec-top {
  padding: 15px 30px;
  margin-top: 15px;
  border: 3px solid #efefef;
}
.account-sec-top h4 {
  font-size: 24px;
}
.amount-due {
  margin-top: 10px;
}
.acc-dropdown {
  position: absolute;
  padding: 18px 15px;
  background: #fff;
  width: 430px;
  z-index: 11;
  height: auto;
  left: 7%;
}
.select-different-account {
  border-bottom: 3px solid #5d5d5d !important;
  color: #5d5d5d;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  border: none;
  padding: 5px;
  width: 100%;
}
.select-different-account:focus {
  outline: none !important;
}
.jeep-drop-heading {
  max-width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.change-account-dropdown li {
  list-style-type: none;
  cursor: pointer;
}

.transparent-btn {
  border-radius: 30px;
  padding: 2px 20px;
  min-width: 200px;
  height: 46px;
  line-height: 24px;
  background: none;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  color: $orange;

  &:hover {
      font-weight: 700;
  }
}

.card-due-amount-warning {
  display: flex;
  font-size: 0.9rem;
}

/* Media Query for mobile */
@media (max-width: 640px) {
  .account-sec-top {
    text-align: center;
  }
  .account-sec-top h4 {
    font-size: 20px;
  }
  .amount-due {
    margin-top: 20px;
  }
  .hideOnMobile {
    display: none !important;
  }
  .acc-dropdown {
    position: absolute;
    padding: 10px 10px;
    background: #fff;
    width: 85%;
    left: 10%;
    z-index: 1;
    height: auto;
  }
  .select-different-account {
    border-bottom: 3px solid #5d5d5d !important;
    color: #5d5d5d;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    padding: 0px;
  }
  .jeep-drop-heading {
    max-width: 270px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .expiry-msz-div {
    height: auto;
  }

  .transparent-btn {
    padding: 2px 10px;
    max-width: 180px;
    height: 36px;
    font-size: 15px;
    text-align: center;
    margin: auto;
  }

  .card-due-amount-warning {
    display: inherit;
    text-align: center;
    margin-top: 4px;
  }

  .dropdown-menu.show {
    transform: translate3d(0px, 27.5px, 0px) !important;
  }

  .mobile-view-link {
    font-size: 12px;
    padding: 28px 0 0;
    margin-top: 5px;
  }

}
  .link-box {
    justify-content: center;
  }

@media (min-width: 640px) and (max-width: 1400px) {
  .transparent-btn {
    padding: 2px 20px;
    width: 200px;
    height: 40px;
    font-size: 14px;
    text-align: center;
  }
}

@media (min-width: 640px) {
  .invisible-on-mobile {
    display: none !important;
  }
  .link-box {
    justify-content: space-between;
  }
}

@media (max-width: 700px) {
.invisible-in-mobile {
  display: none !important;
  }
}

/* Media query for large devices */
@media (min-width: 1400px) {
  .acc-dropdown {
    position: absolute;
    padding: 28px 28px;
    background: #fff;
    width: 500px;
    left: 5%;
    z-index: 11;
    height: auto;
  }
  .select-different-account {
    border-bottom: 3px solid #5d5d5d !important;
    color: #5d5d5d;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    padding: 0px;
  }
  .jeep-drop-heading {
    max-width: 500px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

/* Media query for extra large */
@media (min-width: 1600px) {
  .acc-dropdown {
    position: absolute;
    padding: 28px 28px;
    background: #fff;
    width: 590px;
    left: 4%;
    z-index: 11;
    height: auto;
  }
  .select-different-account {
    border-bottom: 3px solid #5d5d5d !important;
    color: #5d5d5d;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    padding: 0px;
  }
  .jeep-drop-heading {
    max-width: 500px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.dropdown-toggle::after {
  color: $orange;
}

.dropdown-menu.show {
  padding: 10px;
  border: 2px solid #efefef;
  border-radius: 0px;
}
.small-icon {
  background: url(../../assets/images/svgs/make-payment.svg);
  background-repeat: no-repeat;
  height: 29px;
  width: 30px;
  margin: 5px 25px 0px;
}

.small-text {
  font-size: 12px;
}

.small-link:hover, .small-link:active {
  color: $orange;
  .small-icon:hover, .small-icon:active {
    background: url(../../assets/images/svgs/hover/make-a-payment-hover.svg);
    background-size: 29px auto ;
    background-repeat: no-repeat;
  }
}
