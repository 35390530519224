@import '../../stylesheets/main.scss';

.my-accounts-container {
    margin-top: 30px;   
}

// .header {
//     &:hover {
//         font-weight: 700;
//     }
// }

.text-theme {
    color: $orange;
}

.text-red {
    color: $red;
}

.select-account {
    width: 360px;
    min-height: 315px;
    background-color: #fff;
    border: 3px solid #EFEFEF;
    padding: 35px;
    box-shadow: 0.5rem 1rem 1.5rem rgba(0, 0, 0, .175);
    &:hover {
        border: solid $orange 3px;
    }
}

.due-amount-warning b {
    font-size: 15px
}

.account-sec h5,
.current-bal-sec h4 {
    font-size: 23px;
}

.due-sec span,
.current-bal-sec span {
    font-size: 14px
}

.due-sec h2 {
    font-size: 44px
}

.current-bal-sec {
    font-size: 44px
}

.warning-icon {
    margin-right: 5px;
    margin-top: -3px;
}

@media(max-width: 400px) {
    .select-account {
        width: 300px;
        min-height: auto;
        background-color: #fff;
        border: 3px solid #EFEFEF;
        padding: 14px;
        margin-left: 0;
    }

    .account-sec,
    .due-sec {
        margin-top: 18px;
    }

    .due-amount-warning b {
        font-size: 17px;
    }
}

@media(max-width: 767px) and (min-width:401px) {
    .select-account {
        width: 340px;
        min-height: auto;
        background-color: #fff;
        border: 3px solid #EFEFEF;
        padding: 14px;
        margin-left: 0;
    }

    .account-sec,
    .due-sec {
        margin-top: 20px
    }

    .account-sec,
    .due-sec {
        margin-top: 18px;
    }

    .due-amount-warning b {
        font-size: 14px;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .select-account {
        width: 400px;
        min-height: 388px;
        background-color: #fff;
        border: 3px solid #EFEFEF;
        padding: 25px;
        margin-left: 23px;
        box-shadow: 0.5rem 1rem 1.5rem rgba(0, 0, 0, .175) !important;
    }

    .account-sec,
    .due-sec {
        margin-top: 18px;
    }
}

.active+.hide {
    display: block !important;
}