@import 'src/stylesheets/_variables.scss';

.content-box {
  display: flex;
  flex-direction: column;
  padding: .5rem;
  margin: 10px;
  width: 200px;
  height: 120px;
  border: 3px solid #EFEFEF;
  cursor: pointer;

  &.orange-border {
    border-color: $orange;
  }

  .shadow-box-body {
    margin: auto;
    text-align: center;
  }

  .shadow-box-footer {
    height: 15px;
    text-align: right;
  }
}
