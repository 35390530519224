$orange: #ff6b45;
$light-gray: #c2c2c2;
$header-gray: #a0a0a0;
$gray: #dcdcdc;
$dark-gray: #9d9d9d;
$almost-black: #222222;
$lighter-black: #222629;
$red: #e04c3c;
$sepia: #9C8B6C;
$green: green;
$darker-gray:#898989;

$note_background: #fcfac7;
$note_border: #fdf2b0;
$admin_note_background: #e5e9f2;
$admin_note_border: #d5e0f2;

$breakpoints: (
  'phone': 320px,
  'tablet': 768px,
  'desktop': 1024px
);

$shay2-green:#446b45;
