@import '../../stylesheets/main.scss';
.alert-container {
    margin: auto 15px;
}

.alert-box{
    background: #24272A;
    padding: 20px 20px;
    margin-top: 15px;
    border: solid 3px black;
}
.payment-expire-block{
    text-align: center;
    display: flex;
    align-items: center;
}
@media(max-width:769px){
    .alert-box {
        width: 349px;
        padding:15px 33px;
    }
}
@media(min-width:769px) and (max-width: 1024px){
    .alert-box {
        width: 250px;
        background: #24272A;
        padding: 40px 15px;
        margin-top: 15px;
        min-height: 177px;
    }
    .expiry-msz-div{
        height: auto;
    }
}
@media(min-width: 1024px) and (max-width:1399px){
    .alert-box {
        width: 250px;
        min-height: 233px;
        background: $almost-black;
        padding: 30px 10px;
        margin-top: 15px;
    }
}
@media screen and (min-width: 1400px){
    .expire-text-msg {
        font-size: 22px;
    }
    .alert-box{
        width: 355px;
        height: 255px;
        background: $almost-black;
    }
}
