@import '../../stylesheets/variables';

.payment-form-container {
  max-width: 800px;
}

.payment-breadcrumb {
  background: none;
}

.numberCircle {
  width: 35px;
  line-height: 33px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  border: 2px solid $almost-black;
  background-color: $almost-black;
  color: white;
}