@import '../../stylesheets/main.scss';

.page-not-found{
    min-height: calc(100vmin - 334px);
    margin: 60px 0;
    padding-top: 28px;
}
.page-not-found-main{
    display: flex;
    justify-content: center;
}
.page-not-found-circle{
    text-align: center;
}
.page-not-found-circle h1{
    font-size: 9em;
}
.page-not-found-circle h2{
    font-size: 1.2em;
    color: $orange;
}
.heading-page-not{
    color: $orange;
    font-size: 2em;
}
.btn-404 {
    background: #fff;
    color: #111;
    border: 1px solid #111;
    margin-top: 29px;
    box-shadow: none;
}
.shadow-0{
    box-shadow: none;
}
.imoji-404{
    width: 128px;
    margin-bottom: 21px;
}
.email-support-404 label{
    font-size: 2em;
}
.contact_support_link{
    font-size: 1.4em;
}

@media (max-width: 720px) {
    .btn-404 {
        margin-top: 17px;
    }
}