@import '../../stylesheets/main.scss';

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 2.5rem ;
}
.custom-control-inline {
    display: inline-flex;
    margin-right: 0rem ;
}
.custom-control-label::before {
    position: absolute;
    top: -0.1rem ;
    left: -2.5rem ;
    display: block;
    width: 26px;
    height: 26px ;
    pointer-events: none;
    content: "";
    border-radius: 0px ;
    background-color: #fff;
    border: #1B1D1F solid 2px !important;
}
.custom-control-label::after {
    position: absolute;
    top: -0.1rem ;
    left: -2.5rem ;
    display: block;
    width: 26px;
    height: 26px;
    content: "";
    background: no-repeat 50%/50% 50%
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $lighter-black;
    background-color: #ffffff;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
}

@media(max-width: 640px){
    .custom-control-label::before {
        position: absolute;
        top: -0.1rem;
        left: -1.5rem;
        display: block;
        width: 20px;
        height: 20px;
        pointer-events: none;
        content: "";
        border-radius: 0px !important;
        background-color: #fff;
        border: #1B1D1F solid 2px !important;
    }
    .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        min-width: 100px;
        margin-left: -16px;
    }
    .custom-control-label::after {
        position: absolute;
        top: -0.3rem;
        left: -1.7rem;
        display: block;
        width: 26px;
        height: 26px;
        content: "";
        background: no-repeat 50%/50% 50%
    }
}