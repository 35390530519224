@import './stylesheets/main';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9em;
  position: relative;
  font-weight: 500;
}

b {
  font-weight: 900
}

a {
  color: $lighter-black;

  &:hover {
    text-decoration: none;
    color: $lighter-black;
  }
}

.cancel-link {
  color: #4f504f;

  &:hover {
    color: $almost-black;
    font-weight: 700;
  }
}

.link-div {
  width: 181px;
  padding-right: 15px;
  padding-left: 15px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-top: 3rem;        /* Navbar height */
  padding-bottom: 20rem;    /* Footer height */
}

.content {
  z-index: 1;
  padding: 1rem 1rem 2rem 1rem;

  @media (max-width:768px) {
    top: 45px;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: $lighter-black;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: $lighter-black;
}

::placeholder {
  color: #515456 !important;
}

.social_security_number,
.password-required,
.password-required-tick {
  position: relative;
}

.social_security_number::before {
  background-image: url(./assets/images/svgs/security-lock.svg);
  height: 26px;
  width: 26px;
  content: '';
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  left: 0px;
  top: 5px;

}

// .password-required::before {
//   background-image: url(./assets/images/svgs/ex.svg);
//   height: 10px;
//   width: 10px;
//   content: '';
//   display: block;
//   position: absolute;
//   background-repeat: no-repeat;
//   left: 0px;
//   top: 6px;
// }

.text-orange {
  color: $orange;
}

.openPopup {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.text-dark {
  color: $almost-black;
}

.alert {
  margin-top: 1rem;
}

.logout-link {
  padding: 5px 26px;
  font-size: 20px;
  border-radius: 30px;
  background: $orange;
  color: $lighter-black;
  border: 1px solid $orange;
  margin-top: 17px;
}

/* .social_security_number{
  padding-left: 30px !important;
} */

// .lock-img{
//   position: absolute;
//   width: 33px;
//   left: 13px;
//   margin-top: 4px;
// }
// .ex-img {
//   position: absolute;
//   width: 10px;
//   left: 16px;
//   margin-top: 6px;
// }

.align-element-center {
  display: flex;
  align-items: center;
  min-height: calc(100vmin - 334px);
  margin-top: 45px
}

.align-element-top {
  min-height: calc(100vh - 349px);
  margin-top: 70px;
}

/* .main-page{
  min-height: calc(100vmin - 160px);
} */
.pl-text {
  padding-left: 28px !important;
}

.user-sec {
  margin-right: 15px
}

.shadow-custom {
  -webkit-box-shadow: 0.5rem 1rem 1.5rem rgba(0, 0, 0, .175);
  box-shadow: 0.5rem 0.5rem 0.6rem rgba(0, 0, 0, .15);
}

.custom-border {
  border: 3px solid #EFEFEF;
}

.tab-contents {
  margin: 30px 0px
}

.modal-l.modal-dialog {
  max-width: 900px;
  margin: 1.75rem auto;
  text-align: center;

  .modal-content {
    max-width: 900px;
  }

  .justify-content {
    justify-content: flex-end;
  }
}

.modal-s.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
  text-align: center;


  .modal-content {
    max-width: 500px;
  }

  .justify-content {
    justify-content: space-between;
  }

  .alert {
    margin: 0 3rem 0 3rem;
  }
}

.no-border {
  border: none;
}

.logo {
  width: 115.5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.centered {
  padding-left: 20px;
  padding-right: 20px;
}

.grey-text {
  color: $header-gray;
}

input {
  width: 100%;
}

/*==media query max-width:768==*/
@media (max-width:768px) {
  .float-xs-right {
    float: right
  }

  .main-page {
    min-height: auto;
    padding-bottom: 20px;
  }

  .h2,
  h2 {
    font-size: 1.7rem !important;
  }

  .h3,
  h3 {
    font-size: 1.5rem !important;
  }

  .user-sec {
    margin-right: 15px
  }

  .logo {
    width: 73.5px;
  }

  .tab-contents {
    margin: 0px 0px
  }

  .greeting-heading {
    margin: auto;
  }

  .modal-s.modal-dialog {
    margin: 1rem;
  }

  .modal-l.modal-dialog {

    // max-width: 900px;
    // //     max-width: 90%;
    // margin: 1.75rem auto;
    // .modal-content {
    //   max-width: 900px;
    // }
    margin: 1rem;
    margin-bottom: 5rem;
    .justify-content {
      justify-content: space-between;
    }
  }

  .link-div {
    width: 116px;
  }

  .align-element-top {
    // min-height: calc(100vh - 349px);
    margin-top: 0px;
  }

  .centered {
    padding: 15px;
  }

  .heading-2 {
  font-size: 25px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: calc(100% - 100px) !important;
  }
}


@media screen and (min-width: 1400px) {

  body {
    font-size: 1rem
  }

  .h4,
  h4 {
    font-size: 1.8rem;
  }

  .h3,
  h3 {
    font-size: 2rem;
  }

  .heading {
    font-size: 44px;
    font-weight: bolder
  }

  .greeting-heading {
    font-size: 44px;
  }

  .login-below,
  .form-control.text-font-size,
  .below-header-text {
    font-size: 25px;
    line-height: 1;
  }

  .msg-text {
    font-size: 20px
  }

  .social_security_number::before {
    height: 40px;
    width: 40px;
    content: '';
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    left: 0px;
    top: 5px;
  }

  .pl-text {
    padding-left: 40px !important;
  }

  .password-required,
  .password-required-tick {
    margin-top: 15px
  }

  .account-menu-img {
    background-size: 62px auto !important;
    background-repeat: no-repeat !important;
    height: 70px !important;
    background-position: center !important;
    width: auto;
    margin: auto;
  }

  .footer-right-content ul li {
    display: inline-block;
    padding: 5px 5px;
    color: $darker-gray;
    font-size: 20px;
  }
}
/* default css */