@import '../../stylesheets/variables';

.footer {
  position: absolute;
  bottom: 0;
  height: 20rem;
  width: 100%;
  background: $almost-black;
  padding: 15px 30px;
}
.footer-right-content ul li {
  display: inline-block;
  padding: 5px 5px;
  color: $darker-gray;
  font-size: 16px;
}
.footer-right-content ul li + li a {
  color: $orange;
  cursor: pointer;
}
.footer-left-content,
.footer-left-content ul li a {
  color: white;
}
.footer-left-content ul li {
  list-style-type: none;
  padding: 3px 0px;
}
.contactForm .textareaHeight {
  height: 100px;
}

/*==media query max-width:768==*/
@media (max-width: 640px) {
  .footer {
    width: 100%;
    background: $almost-black;
    padding: 15px 20px;
  }
  .footer-right-content ul li {
    display: inline-block;
    padding: 5px 5px;
    color: $darker-gray;
    text-align: center;
  }
}

/* 6-1-2020 */
.btn-custom-primary.btn {
  font-weight: 400;
}
/* end of 6-1-2020 */
