.loader-bg{
    background: rgb(56, 56, 56);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left:0;
    opacity: 0.9;
    z-index: 1;
}
.loader-img{
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 100px;
    z-index: 11;
}
