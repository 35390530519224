@import '../../stylesheets/main.scss';

.tab-links {
  margin-top: 10px;
}
.tab-links li {
  list-style-type: none;
  display: inline;

  margin-right: 15px;
}
.tab-links li label:hover,
.tab-links li label.active {
  color: $orange !important;

  border-bottom: 2px solid $orange;
}
.tab-links li label.active {
  font-weight: 800;
}
.tab-links li label {
  color: #696b6d !important;
  font-size: 16px;
  padding: 10px 0px;
  border-bottom: 2px solid transparent;
}
@media (max-width: 600px) {
  .tab-links {
    line-height: 2.5;
  }
}
.input-column i {
  position: absolute;
  right: 10px;
  top: 11px;
}

.form-label {
  margin: 0;
  font-size: 15px;
}

.not_label {
  margin-top: 24px;
}

.Update_customer_body .form-control {
  margin-bottom: 15px;
}

.Update_customer_footer .btn-custom-primary.close {
  font-weight: 100;
  font-size: 1rem;
}

#Update_customerLabel {
  font-weight: 700;
}

#Update_customer .modal-header,
#Update_customer .modal-footer {
  border: 0;
}

// .check_this .form-group {
//   position: relative;
//   margin-bottom: 20px;
// }

.check_this input[type='checkbox'] {
  display: none;
}

.check_this .form-check label {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  padding-left: 29px;
}

.check_this .form-check label:before,
.check_this .form-check label:after {
  content: '';
  position: absolute;
}

.check_this .form-check label:before {
  left: 0;
  top: 2px;
  height: 18px;
  width: 18px;
  border: 1px solid #828282;
  border-radius: 3px;
}

.check_this .form-check label:after {
  left: 3px;
  top: 6px;
  height: 6px;
  width: 12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg) scale(0);
  -ms-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: all 0.3s linear;
}

.check_this .form-check input:checked + label::before {
  background: #045fa9;
  border-color: #045fa9;
}

.check_this .form-check input:checked + label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  -ms-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.tooltipBox strong {
  font-size: 14px;
}

.tooltipBox p {
  font-size: 11px;
}

.tooltipBox {
  bottom: 28px;
  right: 0;
  min-width: 400px;
  background: #24272a;
  color: #fff;
  padding: 15px 15px 15px 0;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.37);
  -ms-box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.37);
  box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.37);
  -webkit-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.tooltipBox::before {
  content: '';
  position: absolute;
  bottom: -7px;
  right: 120px;
  border-top: 7px solid #24272a;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

#CreditCard .input-type-text {
  margin-bottom: 20px;
}
.credit-dtl p {
  color: #a2a2a2;
  font-weight: 500;
}

/* =============== media queries ================ */

@media screen and (min-width: 768px) {
  .tooltipBoxParent:hover .tooltipBox {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
  .tooltipBox {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    visibility: hidden;
    position: absolute;
  }
}

@media screen and (max-width: 767px) {
  .tooltipBox {
    display: none;
    bottom: 10px;
  }
  .tooltipBoxParent:hover .tooltipBox {
    display: block;
  }
}

/* =============== end of media queries ================ */
.code {
  cursor: pointer;
}

.container-button {
  display: flex;
  cursor: pointer;

  .icon-section {
    width: 80px;
    height: 80px;
    background-color: #e26e02;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .icon {
    width: 50px;
    height: 50px;
  }
  .button-itself {
    width: 400px;
    height: 80px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ee915f;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -30px;
  }
  .text-button {
    margin-left: 20px;
    font-family: "Inter", sans-serif;
    margin-bottom: 0px;
  }
}


.container-arrow-button {
  display: flex;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
  
  .img-arrow {
    width: 40px;
    height: 30px;
    margin-left: 10px;
  }
  .image-div-arrow {
    flex-basis: 9%;
  }
  .text-arrow {
    font-size: 15px;
    padding-left: 20px;
    font-family: "Inter", sans-serif;
    margin-top: 15px;
    font-weight: bold;
  }
}

.orange-container {
  background-color: #ff7c33;
  width: 320px;
  height: 150px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  border: 3px solid black;

  .white-circle {
    background-color: white;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
  }
  .phone-icon {
    width: 50px;
    height: 50px;
  }
  .text-click-here {
    margin-top: 6px;
    text-align: center;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: white;
  }
  .image-container{
    display: flex;
    justify-content: center;
    margin-top: 7px;
  }
}
