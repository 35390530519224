@import '../../stylesheets/main.scss';

.input-type-text {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5;
    color: #565858;
    background-color: #fff;
    background-clip: padding-box;
    border-bottom: 3px solid $lighter-black;
    border-radius: 0;
    border: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.input-type-text:focus {
    color: #575858;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}