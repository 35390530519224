@import '../../stylesheets/main.scss';

.autopay-method-card{
    width: 420px;
    padding: 40px;
    margin-top: 50px;
    margin-right: 22px;
    border: 3px solid #EFEFEF;
}
.autopay-card-heading, .autopay-list-heading{
    font-size: 20px;
}
.edit-card{
    cursor: pointer;
}
.chase-card{
    width: 56.89px
}
.autopay-date-price{
    margin-top: 30px
}
.autopay-date, .autopay-price{
    margin-right:33px;
}
.autopay-method-lists{
    margin-top: 60px
}
.autopay-list{
    margin-top: 55px
}
.bank-card-list{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 32px;;
    border: 1px solid #333;
}
.bank-card-name{
    min-width: 74%;
    padding: 20px;
}
.bank-card-heading{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 29px 0px 0px;
}
.autopay-list-heading{
    min-width: 74%;
}
.bank-card-type, .card-delete-icon, .hidden-space{
    padding: 20px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 29px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8c8b8b;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: $orange;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $orange;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .card-delete-icon{
      color: #8c8b8b;
      cursor: pointer;
  }
  .autopay-toggle-heading, .bank-card-type{
    font-size: 20px;
    padding: 0px 20px;
  }


  .card-delete-icon:hover{
    color: $orange;
    cursor: pointer;
}


.autopay-list table thead tr th{
    font-size: 20px;
    padding: 9px 30px;
}
.autopay-list table thead tr th:first-child{
    padding-left: 0;
    width: 70%;
}
.autopay-list table thead tr th:first-child u{
   cursor: pointer;
}
.autopay-list table tbody tr{
    padding: 15px !important;
    border: 1px solid #24272A;
    vertical-align: middle;
}
.autopay-list table tbody tr td{
    padding: 16px 34px;
    vertical-align: middle;
}
/*popup model*/
.modal-backdrop.show {
    opacity: 0.8;
    background: #989898;
}
// .modal-content {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     pointer-events: auto;
//     background-color: #fff;
//     background-clip: padding-box; 
//     border: none; 
//     border-radius: 0rem; 
//     outline: 0;
// }
.footer-model{
    padding: 3% 4%;
    cursor: pointer;
}
// .modal-dialog {
//     max-width: 90%;
//     margin: 1.75rem auto;
// }
// .modal-content{
//     height: auto;
// }
// .modal-body{
//     padding: 7% 5%
// }
/* The custom-radio */
.custom-radio {
    position: relative;
    padding-left: 26px;
    margin-right: 14px;
    font-size: 24px;
    margin-top: 45px;
}

/* Hide the browser's default radio button */
.custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 8px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 3px solid #6F7271;
    border-radius: 50%;
}


/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
    background-color: $orange;
}

.delete-card-name{
    margin-top: 20px;
    font-size: 20px
}

.borders {
    margin-top: 10px;
    border: 2px solid #eee;
}
@media (min-width: 576px){
    // .modal-dialog {
    //     max-width: 85%;
    //     margin: 6% auto;
    // }
    // .modal-content{
    //     height: unset;
    // }
}
@media (min-width: 1200px){
    // .modal-dialog {
    //     max-width: 895px;
    //     margin: 6% auto;
    // }
    // .modal-body{
    //     padding: 60px 67px;
    // }
    // .modal-content{
    //     max-height: auto;
    //     overflow: auto
    // }
    
}
@media (min-width: 1400px){
    .tcolumn {
        width: 130px;
    }

    // .modal-dialog {
    //     max-width:1041px;
    //     margin: 6% auto;
    // }
    // .modal-body{
    //     padding: 75px 95px
    // }
    // .modal-content{
    //     max-height: 629px;
    //     height: 629px;
    //     overflow: auto
    // }
    
}
/*end model */
@media(max-width:768px){
    .autopay-list table tbody tr td {
        padding: 9px 13px;
    }
    .autopay-list table thead tr th:first-child{
        padding-left: 0;
        min-width: 260px;
    }
    .autopay-list table thead tr th {
        font-size: 16px;
        padding: 9px 11px;
    }
    .autopay-method-card {
        width: 369px;
        padding: 22px;
        margin-right: 0px;
        margin-top: 16px;
        border: 3px solid #EFEFEF;
    }
    .bank-card-heading {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0px 19px 0px 0px;
    }
    .autopay-card-heading, .autopay-list-heading{
        font-size: 16px;
    }
    .autopay-toggle-heading, .bank-card-type{
        font-size: 16px;
        padding: 0px 20px;
      }
    .autopay-method-lists{
        margin-top: 30px
    }
    .autopay-list{
        margin-top: 25px;
        width: 100%;
         overflow: scroll;
    }
    .bank-card-name {
        min-width: auto;
        padding: 13px;
    }
    .bank-card-list {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0px;
        border: 1px solid #333;
    }
    .bank-card-type, .card-delete-icon, .hidden-space {
        padding: 6px;
    }
    .autopay-list-heading {
        min-width: auto;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 47px;
        height: 22px;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 19px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        transition: .4s;
    }
    .text-hide-mbl i{
        display: grid;
        display: none;
    }
}