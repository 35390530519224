@import '../../stylesheets/main.scss';
.new {
    border-bottom: solid 2px #dbdada;
}

.hide {
    pointer-events: none;
    display: none;
}

.scrolly {
    overflow: scroll;
    max-height: 300px;
}

.due-payment-icon{
    width: 15px;
}
.payment-history-table thead tr{
    border: none
}
/* .payment-history-table tr{
    border: 1px solid #eee
} */
.payment-history-table{
    width: calc(100% - 2px);
}
.payment-history-table td{
    border-top: unset ;
    min-width: 100px;
    color: #58595B;
}
.payment-history-table tr.odd-row{
 background: #f3f3f3;
}
.payment-history-table tr.odd-row:nth-child(even) {
    background: #f3f3f3;
    border-top:none;
}
.payment-history-table tr.even-row:nth-child(odd) {
    background: #FFF;
    border-top:none;
}
.payment-history-table td:last-child {
    padding: .75rem;
    vertical-align: top;
    
}
.payment-history-table td:first-child {
    padding-left: 25px;
    min-width: 150px;
}
.payment-history-table tbody.collapse.in {
    display: table-row-group;
  }
.payment-history-table thead th {
    vertical-align: bottom;
    border-bottom: unset;
    border-top: none;
    text-transform: uppercase;
    color: #848181;
}
.table-row-collapse td{
    font-size: 0.9em
}
.payment-history-table .hide{
    display: none;
}
.payment-history-table .show{
    display: table-row;
}
#myTable th:first-child, #myTable td:first-child {
    min-width: 250px;
    /* width: 250px; */
}
.transition {
    transition: all .3s linear;
}
.payment-history-year{
    display: none
}
.payment-history-table .show-more-btn{
    display: none
}
.history-filter{
    display: block;
    min-width: 250px;
    margin-left: 70px;
    position: relative;
}
.history-filter i{
    font-size: 26px;
    margin-left: 8px;
    position: absolute;
    right: 22px;
    top: 7px;
}
.filter-box{
    position: relative;
    font-size: 20px;
    font-weight: 500;
    color: #212529;
    background: #ffffff;
    width: 100%;
    padding: 5px 20px;
    margin-bottom: 10px;
    border: 1px solid #d4d3d3;
    /* box-shadow: 3px 5px 9px 0px rgba(117, 115, 115, 0.5); */
    border-radius: 4px;
}
.payment-history-options{
    min-width: 100%;
    box-shadow: 3px 5px 9px 0px rgba(117, 115, 115, 0.5);
    padding: 0px;
    position: absolute;
    left: 0;
    top: 42px;
    background: #fff;
    z-index: 1;
}
.payment-history-options li{
    display: block;
    list-style: none;
    padding: 5px 20px;
    font-size: 18px;
    cursor: pointer;
}
.payment-history-options li:hover{
    background: #fff;
    color: $orange;
}
.payment-history-options li:last-child{
    border-top:2px solid #eee;
    padding-bottom: 15px;
}
.payment-history-options li:last-child:hover{
    background: unset;
    color: #333;
    
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon, .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: $orange !important;
}
.react-daterange-picker__wrapper {
    display: flex;
    border: thin solid gray;
    padding: 5px;
}

.question-icon{
    margin-right: 9px;
    background: #969696;
    color: #171515;
    padding: 5px 8.5px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
}
.cross-icon{
    background: $orange;
    margin-right: 9px;
    color: #fff;
    padding: 5px 6.6px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
}
.mail-icon{
    color: #a0a0a0;
    cursor: pointer;
    
}
.fa-envelope.mail-icon:before{
    font-size: 20px;
}
fieldset {
    min-width: auto;
    padding: 17px;
    margin: 0px;
    border: 2px solid #eee;
}
legend {
    display: block;
    width: auto;
    max-width: 100%;
    padding: 0px 9px;
}
.bg-gh-white{
    background: #f8f8ff;
}
.mobile-td-only{
    display: none
}

.custom-height {
    height: auto;
}

@media(min-width: 1400px){
    .payment-of{
        font-size: 1.5rem
    }
    .due-payment-icon{
        width: 25px !important;
    }
}
@media(max-width: 768px){
    .table thead th {
        font-size: 13px;
    }
    .acct-summary-align-element-top {
        min-height: calc(100vmin - 217px);
        margin-top: 33px;
    }
    #myTable th:first-child, #myTable td:first-child {
        min-width: 170px;
        /* width: 170px; */
    }
    
}
@media(max-width: 640px){
    .mt-mbl-xs{
        margin-top: 20px
    }
    .history-filter{
        margin-left: 0px;
    }
    .payment-history-table {
        width: calc(100% - 0px)!important;
    }
    .payment-history-table td:last-child {
        padding: 0rem 10px !important;
        border-right: none !important;
    }
    .payment-history-table td {
        color: #58595B;
        padding: 4px !important;
    }
    .table-accordion{
        border:none !important
    }
    .payment-history-table .hide, .payment-history-table .tbl-hide, .d-none-mobile{
        display: none !important;
    }
    .payment-history-table .tbl-show{
        display: block;
    }
    .show-more-btn{
        display: inline-block;
        padding: 0;
        min-width: 100px;
        height: auto;
        cursor: pointer;
    }
    .payment-history-year{
        display: flex;
        font-size: 17px;
        padding: 7px 11px;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        border-bottom: 1px solid #eee;
    }
    .responsiveTable tbody tr.table-row-collapse {
        border:1px solid #dedede !important;
    }
    .responsiveTable td.pivoted.mobile-td-only{
        padding: calc(0px + 0px) !important;
        white-space: inherit;
        
    }
    .fees-all{
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #eee;
        padding: 7px 0px 0px 1rem;
        background: ghostwhite;
    }
    .responsiveTable td.pivoted.mobile-td-only .fees-all>div{
        width:50%;
        margin-bottom: 10px;
    }
    .payment-history-table  .responsiveTable .tbody tr {
        border: 1px solid #d8d8d8;
        padding: 0em;
    }
    fieldset {
        min-width: auto;
        padding: 5px;
        margin: 0px;
        border: 2px solid #eee;
    }
    .hide-sm{
        display: none !important;
    }
    .payment-history-options li:hover {
        background: #fff;
        color: $orange;
    }
    .payment-history-table.responsiveTable tbody tr{
        border-color:#d8d8d8;
    }
}
.active + .hide {

    display: block !important;

}
.invisible {
  display: none;
}
