@import '../../stylesheets/main.scss';

.select-input {
    display: block ;
    width: 100% ;
    height: calc(1.5em + .75rem + 2px) ;
    padding: .375rem .75rem ;
    font-style: italic;
    line-height: 1.5 ;
    color: #495057 ;
    background-color: #fff ;
    background-clip: padding-box ;
    border-bottom: 3px solid $lighter-black;
    border-radius: 0;
    border-top: none ;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out ;
    border-left: none ;
    border-right: none ;
}
.select-input:focus {
    color: #495057 ;
    background-color: #fff ;
    border-color: $lighter-black;
    outline: 0 ;
    box-shadow: none ;
}