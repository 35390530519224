button:focus {
    outline: 1px dotted;
    outline: none !important;
}

@import '../../stylesheets/main.scss';

.btn-custom-primary {
    background: $almost-black;
    border: 1px solid $lighter-black;
    border-radius: 30px;
    padding: 2px 23px;
    color: #fff;
    min-width: 155px;
    height: 46px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
    line-height: 24px;

    &:hover {
        font-weight: 700;
    }
}

.alert-button {
    background: $orange;
    color: $lighter-black;
    border: 1px solid $orange;
}

@media(max-width:1399px) {
    .btn-custom-primary {
        background: $almost-black;
        border: 1px solid $lighter-black;
        border-radius: 30px;
        padding: 2px 23px;
        color: #fff;
        min-width: 144px;
        height: 35px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
        line-height: 24px;
    }

    .alert-button {
        background: $orange;
        color: white;
        border: 1px solid $orange;
    }
}

@media only screen and (min-width: 1400px) {
    .alert-button {
        background: $orange;
        color: $lighter-black;
        border: 1px solid $orange;
    }
}

@media (max-width: 640px) {
    .btn-custom-primary {
        min-width: 120px;
    }
}