/* Account menu CSS */
@import '../../stylesheets/main.scss';

.accounts-menu {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%
}

.accounts-menu li.list-group-item {
    display: inline-block ;
    min-width: 160px;
    // margin-right: 50px;
    padding: 5px 0px !important;
    border-bottom: 4px solid transparent !important;
}
.accounts-menu li:hover, .accounts-menu li.active{
    border-bottom: 4px solid $orange !important;
    border-radius: 0;
    display: inline-block ;
    box-shadow:unset
    
}
.accounts-menu li.active span, .accounts-menu li:hover span{
    color: $orange
}
.accounts-menu li .account-menu-img{
    background-size: 45px auto ;
    background-repeat: no-repeat ;
    height: 50px ;
    background-position: center ;
    width: 58px;
    margin: auto;
}
/* Icons in menu background */
.account-summary{
    background: url(../../assets/images/svgs/account-summary.svg);
}
.accounts-menu li:hover .account-summary, .accounts-menu li.active .account-summary{
    background: url(../../assets/images/svgs/hover/account-summary-hover.svg);
    background-size: 45px auto ;
    background-repeat: no-repeat ;
    height: 50px ;
    background-position: center ;
    width: 58px;
    margin: auto;
}
.make-a-payment{
    background: url(../../assets/images/svgs/make-payment.svg);
}
.accounts-menu li:hover .make-a-payment, .accounts-menu li.active .make-a-payment{
    background: url(../../assets/images/svgs/hover/make-a-payment-hover.svg);
    background-size: 45px auto ;
    background-repeat: no-repeat ;
    height: 50px ;
    background-position: center ;
    width: 58px;
    margin: auto;
}
.payment-methods{
    background: url(../../assets/images/svgs/payment-method.svg);
}
.accounts-menu li:hover .payment-methods, .accounts-menu li.active .payment-methods{
    background: url(../../assets/images/svgs/hover/payment-methods-hover.svg);
    background-size: 45px auto ;
    background-repeat: no-repeat ;
    height: 50px ;
    background-position: center ;
    width: 58px;
    margin: auto;
}
.my-coverages{
    background: url(../../assets/images/svgs/coverages.svg);
}
.accounts-menu li:hover .my-coverages, .accounts-menu li.active .my-coverages{
    background: url(../../assets/images/svgs/hover/coverage-hover.svg);
    background-size: 45px auto ;
    background-repeat: no-repeat ;
    height: 50px ;
    background-position: center ;
    width: 58px;
    margin: auto;
}

.statements-and-notifications{
    background: url(../../assets/images/svgs/statements.svg);
}
.accounts-menu li:hover .statements-and-notifications, .accounts-menu li.active .statements-and-notifications{
    background: url(../../assets/images/svgs/hover/statement-hover.svg);
    background-size: 45px auto ;
    background-repeat: no-repeat ;
    height: 50px ;
    background-position: center ;
    width: 58px;
    margin: auto;
}
.toolTP{
    position: absolute !important;
    top: 0;
}

/* media query for tablets landscap mode */
@media (min-width: 768px) and (max-width: 1023px) {
    .accounts-menu li.list-group-item {
        margin-right: 0px;
        min-width: 126px;
    }
    .acct-menu-align-element-top {
        margin-top: 60px !important;
    }
    .account-sec-top h4 {
        font-size: 20px;
    }
  }

/* media query for desktop */
@media (min-width: 1024px) and (max-width: 1280px) {
    .accounts-menu li.list-group-item {
        // margin-right: 20px;
    }
}

/* media query for tablets */
@media(max-width: 767px){
   
    .list-responsive {
        display: block;
        width: 100%;
        z-index:0 !important
    }
    .account-menu-img {
        background-size: 35px auto !important;
        background-repeat: no-repeat !important;
        height: 50px !important;
        background-position: center !important;
        width: 58px;
        margin: auto;
    }
    .accounts-menu li.list-group-item {
        display: inline-block ;
        font-size: 14px;
        width: max-content;
        min-width: unset;
        margin-right: 15px;
    }
    
}

/* media query for large devices */
@media screen and (min-width: 1400px){
    .accounts-menu li.list-group-item {
        display: inline-block !important;
        // margin-right: 83px;
        border-bottom: 5px solid transparent !important;
        padding: 5px 0px !important;
        border-radius: 0px;
        box-shadow: unset
    }
    .accounts-menu li:hover, .accounts-menu li.active{
        border-bottom: 5px solid $orange !important;
        border-radius: 0;
        display: inline-block !important;
        box-shadow:unset
        
    }
    .account-menu-img{
        background-size:59.31px 50px !important;
        background-repeat: no-repeat !important;
        height: 50px !important;
        background-position: center !important;
        width: auto;
        margin: auto;
        margin-bottom: 15px;
    }
    .account-menu-name{
        font-size: 20px;
    }
}

/* Media query for Mobile view */
@media(max-width:640px){
    
    .list-group-custom{
        padding: 0
    }
    .accounts-menu{
        position: absolute;
        background: rgb(237, 237, 237);
        top:52px;
        width:282px;
        left: 0;
        opacity: 0.95;
    }
    .account-menu-name{
        font-size: 17px
    }
    .accounts-menu li.list-group-item {
        display: flex;
        align-items: center;
        animation: FadeIn 1s linear;
        animation-fill-mode: both;
        padding: 7px 4px !important;
        border-left: 2px solid transparent !important;
        border-bottom: unset !important
    }
    .accounts-menu li:hover, .accounts-menu li.active{
        border-left: 2px solid $orange !important;
        border-radius: 0;
        box-shadow:unset;
        border-bottom: unset !important
        
    }
    .accounts-menu li.active span, .accounts-menu li:hover span{
        color: $orange
    }
    @keyframes FadeIn { 
        0% {
          opacity: 0.2;
          left: -20px;
        }
        20% {
            opacity: 1;
            left:0
        }
      }
      .accounts-menu .delay:nth-child(1) { animation-delay: .5s }
      .accounts-menu .delay:nth-child(2) { animation-delay: 1s }
      .accounts-menu .delay:nth-child(3) { animation-delay: 1.5s }
      .accounts-menu .delay:nth-child(4) { animation-delay: 2s }
}