@import '../../stylesheets/main.scss';

.custom-navbar {
  z-index: 3;
  height: 3rem;
}

.navbar{
    background: #222222;
    position: fixed;
    color: #fff;
    z-index: 101;
    width: 100%;
    padding: 10px 30px !important;
    top: 0;
}
.navbar-light .navbar-toggler-icon {
    background-image: url(../../assets/images/svgs/hamburger.svg) !important;
}
.navbar-collapses-hidden{
    width: auto;
    right: 0;
    top: -1000px !important;
    display: none;
}
.navbar-collapses{
    position: absolute;
    right: 0;
    height: calc(100vh - 60px);
    width: 250px;
    padding: 27px;
    background: #F1F2F2;
    top: 60px;
    z-index: 1;
    display: block
}
.fa-caret-down, .fa-caret-up{
  cursor: pointer;
}
.hideOn{
  display: none !important
}
.transition{
    transition: all .5s linear;
}
.user-icon{
    width: 40px;
    cursor: pointer;
}
.close-icon{
    width: 18px;
    cursor: pointer;
}
.navbar-toggler{
    padding: 0 !important
}
.list-group-item {
    position: relative;
    display: block;
    padding: 1rem 0.25rem !important;
    margin-bottom: -1px !important;
    background-color: transparent !important;
    border: none !important;
}
.side-navbar{
  top:73px;
}
.menu-line-top, .menu-line-middle, .menu-line-bottom{
  height: 5px;
  width: 39px;
  background: white;
  transition: all 0.2s ease;
}
.menu-line-top{
    transition: all 0.2s ease 0s;
    transform: none;
    transform-origin: left top;
    margin-bottom: 8px;
}
.menu-line-middle{
    transition: all 0.2s ease 0s;
    opacity: 1;
    transform: none;
}
.menu-line-bottom{
    transition: all 0.2s ease 0s;
    transform: none;
    transform-origin: left top;
    margin-top: 8px;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.user-menu-img{
  background-size: 40px auto !important;
  background-repeat: no-repeat !important;
  height: 50px !important;
  background-position: left !important;
  margin: auto;
}
.account-user{
  background: url(../../assets/images/png/account.png);
}
.account-user:hover, .account-user.active{
  background: url(../../assets/images/png/active/account-active.png);
}
.message-center{
  background: url(../../assets/images/png/message-center.png);
}
.message-center:hover, .message-center.active{
  background: url(../../assets/images/png/active/message-center-active.png);
}
.account-service{
  background: url(../../assets/images/png/account-service.png);
}
.account-service:hover, .account-service.active{
  background: url(../../assets/images/png/active/account-service-active.png);
}
.profile{
  background: url(../../assets/images/png/profile.png);
}
.profile:hover, .profile.active{
  background: url(../../assets/images/png/active/profile-active.png);
}
.logout{
  background: url(../../assets/images/png/logout.png);
  a {
    display: block;
  }
}
.logout:hover, .logout.active{
  background: url(../../assets/images/png/active/logout-active.png);
}
.logout:hover a, .logout.active a,
.profile:hover, .profile.active,
.account-user:hover, .account-user.active, 
.account-summary:hover, .account-summary.active,
.make-a-payment:hover, .make-a-payment.active, 
.payment-methods:hover, .payment-methods.active,
.my-coverages:hover, .my-coverages.active,
.statements-and-notifications:hover, .statements-and-notifications.active {
  color: $orange !important;
}
.item {
  border-right: 3px solid transparent;
}
.item:hover, .item.active {
  border-right: 3px solid $orange;
}

.account-summary:hover, .account-summary.active{
  background: url(../../assets/images/svgs/hover/account-summary-hover.svg);
}

.make-a-payment:hover, .make-a-payment.active {
  background: url(../../assets/images/svgs/hover/make-a-payment-hover.svg);
}

.payment-methods:hover, .payment-methods.active {
  background: url(../../assets/images/svgs/hover/payment-methods-hover.svg);
}

.my-coverages:hover, .my-coverages.active {
  background: url(../../assets/images/svgs/hover/coverage-hover.svg);
}

.statements-and-notifications:hover, .statements-and-notifications.active {
  background: url(../../assets/images/svgs/hover/statement-hover.svg);
}
@media(max-width: 768px){
    .navbar-collapses{
        position: fixed;
        width: 80%;
        padding: 27px;
    }
    .navbar{
        background: $lighter-black;
        position: fixed;
        color: #fff;
        z-index: 999;
        width: 100%;
        padding: 4px 10px !important;
        top: 0;
    }
    .side-navbar{
      top:45px;
    }
    .menu-line-top{
      height: 3px !important;
      width: 22px !important;
      margin-bottom: 4px !important;
    }
    .menu-line-middle{
      height: 3px !important;
      width: 22px !important;
    }
    .menu-line-bottom{
      height: 3px !important;
      width: 22px !important;
      margin-top: 5px !important;
    }
}
.hideOndesk{
  display: none
}
@media(max-width:640px){
  .hideOn{
    display: flex !important
  }
}
@media(max-width:768px) and (min-width:641px){
  .side-navbar{
    top:48px;
  }
}