.orange-checked {
  input {
    visibility: hidden;
  }

  span {
    height: 18px;
    width: 18px;
    margin-right: 2px;
    border: 1px solid grey;
    border-radius: 3px;
    display: flex;
  }

  .orange-checkbox {
    background-color: #ff6b45;
    border-color: #ff6b45;

    &::before {
      content: "\2713";
      color: white;
      position: relative;
      top: -2px;
      left: 2px;
    }
  }
}




