@import '../../stylesheets/main.scss';

.user-profile-bg {
  height: 250px;
  width: 100%;
  background: #6f7271;
  margin-top: -30px;
}
.user-profile-main {
  position: relative;
  text-align: center;
}
.user-profile-img {
  position: relative;
  width: 121.49px;
  margin-top: -64px;
  background: #fff;
  border-radius: 50%;
}
.user-details {
  margin-top: 24px;
}
.update {
  cursor: pointer;
}

/* style for Modal */
.input-column i {
  position: absolute;
  right: 10px;
  top: 11px;
}

.form-label {
  margin: 0;
  font-size: 15px;
}

.not_label {
  margin-top: 24px;
}

.Update_customer_body .form-control {
  margin-bottom: 15px;
}

.Update_customer_footer .btn-custom-primary.close {
  font-weight: 100;
  font-size: 1rem;
}

#Update_customerLabel {
  font-weight: 700;
}

// .check_this .form-group {
//   position: relative;
//   margin-bottom: 20px;
// }

.check_this input[type='checkbox'] {
  display: none;
}

.check_this .form-check label {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  padding-left: 29px;
}

.check_this .form-check label:before,
.check_this .form-check label:after {
  content: '';
  position: absolute;
}

.check_this .form-check label:before {
  left: 0;
  top: 2px;
  height: 18px;
  width: 18px;
  border: 1px solid #828282;
  border-radius: 3px;
}

.check_this .form-check label:after {
  left: 3px;
  top: 6px;
  height: 6px;
  width: 12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg) scale(0);
  -ms-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.check_this .form-check input:checked + label::before {
  background: $orange;
  border-color: $orange !important;
}

.check_this .form-check input:checked + label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  -moz-transform: rotate(-45deg) scale(1);
  -ms-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.pac-container {
  z-index: 1051 !important;
}
